var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("el-main", { staticClass: "main" }, [
    _c("div", { staticClass: "title" }, [_vm._v("专利服务")]),
    _c("div", { staticClass: "sep" }),
    _c(
      "div",
      { staticClass: "content" },
      _vm._l(_vm.sections, function(section) {
        return _c(
          "div",
          { key: section.index },
          [
            _c("div", { staticClass: "section" }, [
              _vm._v(_vm._s(section.title))
            ]),
            _vm._l(section.files, function(file) {
              return _c(
                "div",
                {
                  key: file.index,
                  staticClass: "row",
                  on: {
                    click: function($event) {
                      return _vm.handleClick(file.url)
                    }
                  }
                },
                [
                  _c("el-image", {
                    staticClass: "row-pdf",
                    attrs: { fit: "cover", src: require("@/assets/pdf.png") }
                  }),
                  _c("span", { staticClass: "row-content" }, [
                    _vm._v(_vm._s(file.index) + "）" + _vm._s(file.name))
                  ]),
                  _c("el-image", {
                    staticClass: "row-download",
                    attrs: {
                      fit: "cover",
                      src: require("@/assets/download.png")
                    },
                    on: {
                      click: function($event) {
                        return _vm.handleDownload(file.url, file.name)
                      }
                    }
                  })
                ],
                1
              )
            })
          ],
          2
        )
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }