<template>
  <el-main class="main">
    <div class="title">专利服务</div>
    <div class="sep" />
    <div class="content">
      <div v-for="section in sections" :key="section.index">
        <div class="section">{{ section.title }}</div>
        <div
          class="row"
          v-for="file in section.files"
          :key="file.index"
          @click="handleClick(file.url)"
        >
          <el-image
            class="row-pdf"
            fit="cover"
            :src="require('@/assets/pdf.png')"
          ></el-image>
          <span class="row-content">{{ file.index }}）{{ file.name }}</span>
          <el-image
            class="row-download"
            fit="cover"
            :src="require('@/assets/download.png')"
            @click="handleDownload(file.url, file.name)"
          ></el-image>
        </div>
      </div>
    </div>
  </el-main>
</template>

<script>
import store from "@/store";
export default {
  name: "others",
  data() {
    return {
      sections: [
        {
          index: 1,
          title: "专利申请业务",
          files: [
            {
              index: 1,
              name: "专利申请",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E7%94%B3%E8%AF%B7%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E7%94%B3%E8%AF%B7.pdf",
            },
            {
              index: 2,
              name: "专利权利恢复请求",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E7%94%B3%E8%AF%B7%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E6%9D%83%E5%88%A9%E6%81%A2%E5%A4%8D%E8%AF%B7%E6%B1%82.pdf",
            },
            {
              index: 3,
              name: "向外国申请专利保密审查请求",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E7%94%B3%E8%AF%B7%E4%B8%9A%E5%8A%A1/%E5%90%91%E5%A4%96%E5%9B%BD%E7%94%B3%E8%AF%B7%E4%B8%93%E5%88%A9%E4%BF%9D%E5%AF%86%E5%AE%A1%E6%9F%A5%E8%AF%B7%E6%B1%82.pdf",
            },
          ],
        },
        {
          index: 2,
          title: "专利缴费业务",
          files: [
            {
              index: 1,
              name: "专利收费减缴备案指引",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E7%BC%B4%E8%B4%B9%E4%B8%9A%E5%8A%A1/1%E4%B8%93%E5%88%A9%E6%94%B6%E8%B4%B9%E5%87%8F%E7%BC%B4%E5%A4%87%E6%A1%88%E6%8C%87%E5%BC%95.pdf",
            },
            {
              index: 2,
              name: "专利缴费",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E7%BC%B4%E8%B4%B9%E4%B8%9A%E5%8A%A1/2%E4%B8%93%E5%88%A9%E7%BC%B4%E8%B4%B9.pdf",
            },
          ],
        },
        {
          index: 3,
          title: "专利事务服务",
          files: [
            {
              index: 1,
              name: "专利权质押登记办事指南",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E4%BA%8B%E5%8A%A1%E6%9C%8D%E5%8A%A1/%E4%B8%93%E5%88%A9%E6%9D%83%E8%B4%A8%E6%8A%BC%E7%99%BB%E8%AE%B0%E5%8A%9E%E4%BA%8B%E6%8C%87%E5%8D%97.pdf",
            },
            {
              index: 2,
              name: "专利登记簿副本及证明文件指引",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E4%BA%8B%E5%8A%A1%E6%9C%8D%E5%8A%A1/2%E4%B8%93%E5%88%A9%E7%99%BB%E8%AE%B0%E7%B0%BF%E5%89%AF%E6%9C%AC%E5%8F%8A%E8%AF%81%E6%98%8E%E6%96%87%E4%BB%B6%E6%8C%87%E5%BC%95.pdf",
            },
            {
              index: 3,
              name: "批量法律状态证明",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E4%BA%8B%E5%8A%A1%E6%9C%8D%E5%8A%A1/3%E6%89%B9%E9%87%8F%E6%B3%95%E5%BE%8B%E7%8A%B6%E6%80%81%E8%AF%81%E6%98%8E.pdf",
            },
            {
              index: 4,
              name: "专利实施许可合同备案办理指引",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E4%BA%8B%E5%8A%A1%E6%9C%8D%E5%8A%A1/4%E4%B8%93%E5%88%A9%E5%AE%9E%E6%96%BD%E8%AE%B8%E5%8F%AF%E5%90%88%E5%90%8C%E5%A4%87%E6%A1%88%E5%8A%9E%E7%90%86%E6%8C%87%E5%BC%95.pdf",
            },
            {
              index: 5,
              name: "专利著录项目变更申报",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E4%BA%8B%E5%8A%A1%E6%9C%8D%E5%8A%A1/%E4%B8%93%E5%88%A9%E8%91%97%E5%BD%95%E9%A1%B9%E7%9B%AE%E5%8F%98%E6%9B%B4%E7%94%B3%E6%8A%A5.pdf",
            },
          ],
        },
        {
          index: 4,
          title: "专利加速授权业务",
          files: [
            {
              index: 1,
              name: "专利优先审查申请",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E5%8A%A0%E9%80%9F%E6%8E%88%E6%9D%83%E4%B8%9A%E5%8A%A1/1%E4%B8%93%E5%88%A9%E4%BC%98%E5%85%88%E5%AE%A1%E6%9F%A5%E7%94%B3%E8%AF%B7.pdf",
            },
            {
              index: 2,
              name: "专利预审备案指南",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E4%B8%93%E5%88%A9%E5%8A%A0%E9%80%9F%E6%8E%88%E6%9D%83%E4%B8%9A%E5%8A%A1/2%E4%B8%93%E5%88%A9%E9%A2%84%E5%AE%A1%E5%A4%87%E6%A1%88%E6%8C%87%E5%8D%97.pdf",
            },
          ],
        },
        {
          index: 5,
          title: "复审及无效",
          files: [
            {
              index: 1,
              name: "专利申请复审请求",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E5%A4%8D%E5%AE%A1%E5%8F%8A%E6%97%A0%E6%95%88/1%E4%B8%93%E5%88%A9%E7%94%B3%E8%AF%B7%E5%A4%8D%E5%AE%A1%E8%AF%B7%E6%B1%82.pdf",
            },
            {
              index: 2,
              name: "专利无效宣告请求",
              url: "https://hndip-file-public.oss-cn-shanghai.aliyuncs.com/%E4%B8%93%E5%88%A9%E4%B8%9A%E5%8A%A1/%E5%A4%8D%E5%AE%A1%E5%8F%8A%E6%97%A0%E6%95%88/%E4%B8%93%E5%88%A9%E6%97%A0%E6%95%88%E5%AE%A3%E5%91%8A%E8%AF%B7%E6%B1%82.pdf",
            },
          ],
        },
      ],
    };
  },
  beforeRouteEnter(to, from, next) {
    store.commit("business/SET_ACTIVEINDEX", { activeIndex: "1" });
    next();
  },
  methods: {
    handleClick(url) {
      window.open(url, "_blank");
    },
    handleDownload(url, name) {
      console.log("handleDownload");
      let link = document.createElement("a");
      link.download = name + ".pdf";
      link.href = url + "?response-content-type=application/octet-stream";
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
  },
};
</script>

<style lang="stylus" scoped>
.main {
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 72px;

  .title {
    font-size: 30px;
    font-family: PingFang-SC-Heavy, PingFang-SC;
    font-weight: 800;
    color: #2C53B0;
    line-height: 42px;
    text-align: center;
  }

  .sep {
    height: 2px;
    background: #2C53B0;
    margin-top: 14px;
  }

  .content {
    padding-top: 12px;
    padding-bottom: 100px;

    .section {
      margin-top: 16px;
      padding-top: 13px;
      padding-bottom: 13px;
      padding-left: 0px;
      font-size: 17px;
      font-family: PingFang-SC-Bold, PingFang-SC;
      font-weight: bold;
      color: #222222;
      line-height: 24px;
      border-bottom: 1px solid #F3F5FB;
    }

    .row {
      padding-top: 13px;
      padding-bottom: 13px;
      border-bottom: 1px solid #F3F5FB;
      word-break: break-all
      &:hover {
        background-color: #F3F5FB;
        cursor: pointer;
        .row-download {
          visibility: visible;
        }

        .row-content {
          font-family: PingFang-SC-Bold, PingFang-SC;
          font-weight: bold;
          color: #2C53B0;
        }
      }

      .row-pdf {
        width: 20px;
        height: 20px;
        vertical-align: middle;
        margin-left: 12px;

      }

      .row-content {
        font-size: 16px;
        font-family: PingFang-SC;
        font-weight: 400;
        color: #222222;
        line-height: 24px;
        vertical-align: middle;
        padding-left: 12px;
      }

      .row-download {
        width: 20px;
        height: 20px;
        float: right;
        vertical-align: middle;
        visibility: hidden;
        margin-right: 15px;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>